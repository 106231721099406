import React, { useState } from 'react';
import './App.css';
import DateTimePicker from './components/DateTimePicker';
import CryptoSelector from './components/CryptoSelector';
import AmountInput from './components/AmountInput';
import UnitPriceDisplay from './components/UnitPriceDisplay';
import ResultDisplay from './components/ResultDisplay';
import Logo from './components/Logo';
import { getCurrentLocalDateTime } from './components/DateTimePicker';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import { Grid, Button, Container } from '@mui/material';
import { Typography } from '@mui/material';

import { fetchCryptoPrice } from './Coingecko';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4caf50', // Green for a financial feel
    },
    secondary: {
      main: '#f57c00', // Amber for contrast
    },
    background: {
      default: '#F6F6F6',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  },
});

function App() {
  var currentDate = getCurrentLocalDateTime();
  const isoDate = new Date(currentDate).toISOString();
  const [date, setDate] = useState(isoDate);
  const [crypto, setCrypto] = useState('bitcoin');
  const [amount, setAmount] = useState(1);
  const [usdValue, setUsdValue] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [error, setError] = useState(null);

  const handleFetchCryptoPrice = async () => {
    try {
      const { usdValue, unitPrice } = await fetchCryptoPrice(crypto, date, amount);
      setUsdValue(usdValue);
      setUnitPrice(unitPrice);
      setError(null);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch the price. Please check the selected cryptocurrency, amount, and date/time and try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className="container">
        <Logo />
        <Typography variant="h1" gutterBottom className="title">
          Time Travel Your Crypto: Price Calculator 💰🕰
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Get real-time and historical cryptocurrency prices in USD.
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Select a cryptocurrency, input the amount, pick a date and time, and hit calculate. It's that simple! 💡
        </Typography>
        <Container container="true" maxWidth="sm" spacing={2}>
          <Grid item xs={12}>
            <CryptoSelector value={crypto} onChange={(e) => setCrypto(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <AmountInput onChange={(e) => setAmount(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker onChange={(e) => setDate(e.target.value)} />
          </Grid>
          <Grid item xs={12} align="center">
            <Button className="button" variant="contained" color="primary" onClick={handleFetchCryptoPrice}>
              Calculate 💡
            </Button>
          </Grid>
          <Grid item xs={12}>
            { error &&
              <Typography variant="body1" gutterBottom color="error">{error}</Typography>
            }
            {!error && <UnitPriceDisplay value={unitPrice} /> }
            {!error && <ResultDisplay value={usdValue} />}
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default App;