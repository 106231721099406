import React from 'react';

import { TextField } from '@mui/material';

const AmountInput = ({ onChange }) => {
  return (
    <TextField
      label="Enter Amount"
      type="number"
      defaultValue={1}
      onChange={onChange}
      fullWidth
      margin="normal"
    />
  );
};

export default AmountInput;