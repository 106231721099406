export const fetchCryptoPrice = async (crypto, date, amount) => {
    console.info(`Fetching the price of ${amount} ${crypto} on ${date}...`);
    const isoDate = new Date(date).toISOString();
    const endpoint = `https://api.coingecko.com/api/v3/coins/${crypto}/market_chart/range?vs_currency=usd&from=${new Date(isoDate).getTime() / 1000}&to=${new Date(isoDate).getTime() / 1000 + 86400}`;
    const response = await fetch(endpoint);
    const data = await response.json();

    if (response.ok && data && data.prices && data.prices.length > 0) {
        const price = data.prices[0][1];
        const usdValue = price * amount;
        const unitPrice = price;
        return { usdValue, unitPrice };
    } else {
        console.error("Failed to fetch the price. Check the API response.");
        throw new Error("Failed to fetch the price. Check the API response.");
    }
};

export default fetchCryptoPrice;