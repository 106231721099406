import React, { useState } from 'react';
import { Typography, IconButton, Snackbar } from '@mui/material';
import { FileCopy } from '@mui/icons-material';

const UnitPriceDisplay = ({ crypto, value }) => {
  const [open, setOpen] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formattedValue = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f5f5f5', padding: '10px' }}>
      <Typography variant="h6" style={{ marginRight: '10px' }}>
        {crypto} Unit Price:
      </Typography>
      <Typography variant="h6" style={{ color: '#4caf50', marginRight: '10px' }}>
        {formattedValue}
      </Typography>
      <IconButton onClick={handleCopyClick} aria-label="Copy">
        <FileCopy />
      </IconButton>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} message="Value copied to clipboard" />
    </div>
  );
};

export default UnitPriceDisplay;