import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

// Function to get the current local date-time string
export const getCurrentLocalDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 4); // Set the minutes to 4 minutes ago
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const DateTimePicker = ({ onChange }) => {
    const [error, setError] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState(getCurrentLocalDateTime());

    const handleOnChange = (event) => {
        const selectedLocalDateTime = new Date(event.target.value);

        // Check if the selected date-time is invalid
        if (isNaN(selectedLocalDateTime.getTime())) {
            setError(true);
            return;
        }

        const now = new Date();

        // Check if the selected date-time is in the future
        if (selectedLocalDateTime > now) {
            setError(true);
            return;
        }

        setSelectedDateTime(event.target.value);

        // Convert the local date-time to ISO format and send to parent component
        const newEvent = {
            ...event,
            target: {
                ...event.target,
                value: selectedLocalDateTime.toISOString(),
            },
        };

        setError(false);
        onChange(newEvent);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Select Date and Time"
                    type="datetime-local"
                    value={selectedDateTime}
                    onChange={handleOnChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error}
                    helperText={error && 'Please select a valid date and time before the current date and time.'}
                />
            </Grid>
        </Grid>
    );
};

export default DateTimePicker;