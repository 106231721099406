import React from 'react';

import { FormControl, InputLabel, Select, MenuItem, Menu } from '@mui/material';

const CryptoSelector = ({ onChange, value }) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>Select Cryptocurrency</InputLabel>
      <Select value={value} onChange={onChange}>
        <MenuItem value="bitcoin">Bitcoin (BTC)</MenuItem>
        <MenuItem value="ethereum">Ethereum (ETH)</MenuItem>
        <MenuItem value="tether">Tether (USDT)</MenuItem>
        <MenuItem value="binancecoin">Binance (BNB)</MenuItem>
        <MenuItem value="riple">XRP (XRP)</MenuItem>
        <MenuItem value="usd-coin">USDC (USDC)</MenuItem>
        <MenuItem value="staked-ether">Lido Staked Ether (STETH)</MenuItem>
        <MenuItem value="dogecoin">Dogecoin (DOGE)</MenuItem>
        <MenuItem value="solana">Solana (SOL)</MenuItem>
        <MenuItem value="the-open-network">Toncoin (TON)</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CryptoSelector;